import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { message: String };

  ask(ev) {
    if (!confirm(this.messageValue)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }
}
