import 'stylesheets/application.css';

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

import Sortable from 'stimulus-sortable';

window.Stimulus = Application.start();

const context = require.context('./controllers', true, /\.js$/);

Stimulus.load(definitionsFromContext(context));
Stimulus.register('sortable', Sortable);
